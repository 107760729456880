import { fetchTemplate } from '@/utils/isServer';
import dayjs from 'dayjs';

interface IData {
  data: object;
  time: number;
}

let map: Map<string, IData>;
if (global.mapHolder) {
  map = global.mapHolder;
} else {
  map = new Map<string, IData>();
  global.mapHolder = map;
}

const MAX_CACHE = 1000 * 60 * 5; // 5 minutos de cace

export const clearCache = () => {
  map.clear();
};

export const fetchSearchAlternate = async (
  baseUrl: string,
  airport_id: number,
  checkin: any,
  checkout: any,
  lat?: any,
  lng?: any
): Promise<any> => {
  const date: number = new Date().getTime();
  const vcheckin = checkin
    ? dayjs(checkin).format('YYYY-MM-DD')
    : dayjs().format('YYYY-MM-DD');

  const vcheckout = checkout
    ? dayjs(checkout).format('YYYY-MM-DD')
    : dayjs().add(1, 'day').format('YYYY-MM-DD');
  const sa_key = `sa_${airport_id}_${vcheckin}_${vcheckout}_${lat}_${lng}`;
  const data: IData = map.get(sa_key);
  if (data && data.time && date - data.time < MAX_CACHE) {
    return data.data;
  }

  const searchData = {
    airport_id: airport_id,
    lower_selling_price: 0,
    higher_selling_price: 0,
    lower_distance_terminal: 0,
    higher_distance_terminal: 0,
    rating: 0,
    start_date: vcheckin,
    end_date: vcheckout,
    lat: lat,
    lng: lng,
    order: 0,
  };
  if (lat || lng) {
    searchData['address'] = 1;
  }

  const searchResponse = await fetch(
    `${baseUrl}/api/Facility/SearchAlternate`,
    {
      ...fetchTemplate,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchData),
    }
  );

  const response = searchResponse.json();
  map.set(sa_key, {
    time: date,
    data: response,
  });
  return await response;
};

export const cacheFetch = async (url: string, text = false): Promise<any> => {
  const date: number = new Date().getTime();
  const data: IData = map.get(url);
  if (data && data.time && date - data.time < MAX_CACHE) {
    return data.data;
  }

  const req = await fetch(url, { ...fetchTemplate });

  const response = text ? await req.text() : await req.json();
  map.set(url, {
    time: date,
    data: response,
  });
  return response;
};

